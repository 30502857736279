import { Box, Button, Container, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {  EditTwoTone } from "@mui/icons-material";

const RecipientsCard = ({
  id,
  name,
  nickName,
  phone,
  bank_account_number,
  bank_name,
  ifsc,
  email,
  pan,
  onClickEdit,
  is_bank_added,
  relationship,
}) => {

  const textRef = useRef(null);
  const [underlineWidth, setUnderlineWidth] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      const textWidth = textRef.current.offsetWidth;
      setUnderlineWidth(textWidth);
    }
  }, []);


  return (
    <Box
      sx={{
        p: { xs: 2, md: 1 },
        boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
        borderRadius: 2,
        my: 3,
        py: { md: 3 },
        bgcolor: "white",
        cursor: "default",
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
          boxShadow: 6,
        },
        "@media (max-width: 375px)": {
          p: 2,
          my: 2,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 0,
          alignItems: "space-between",
          position: "relative",
          mb: 2,
        }}
      >
        <Container sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            ref={textRef}
            variant="h5"
            fontWeight={400}
            sx={{
              position: "relative",
              fontSize: { xs: "1rem", sm: "1.5rem" },
              "&:after": {
                content: '""',
                position: "absolute",
                bottom: -8,
                left: 0,
                width: `${underlineWidth}px`, // Dynamic width
                height: 3,
                backgroundColor: "#15ad87",
                transition: "width 0.3s ease", // Optional animation
              },
            }}
          >
            {nickName.toUpperCase()}
          </Typography>
        </Container>
        <EditTwoTone
          style={{
            width: "24px",
            height: "24px",
            color: "#00796b",
            marginBottom: "5px",
            cursor: "pointer !important",
            transition: "color 0.3s ease",
          }}
          sx={{
            cursor: "pointer",
            "&:hover": {
              cursor: "pointer",
            }
          }}
          onClick={() => {
            onClickEdit({
              name,
              nickName,
              email,
              ifsc,
              bank_account_number,
              phone,
              id,
              relationship,
            });
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = "#004d40")}
          onMouseLeave={(e) => (e.currentTarget.style.color = "#00796b")}
        />
      </Box>

      <Box>
        {/* {phone.length = 10 && <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 0,
            alignItems: "center",
          }}
        >
          <Typography variant="body1" lineHeight={1.2}>
            Phone:
          </Typography>
          <Typography color="text.secondary" variant="body1" lineHeight={1.2}>
            {formatPhone(phone)}
          </Typography>
        </Container>} */}

        {email && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Typography variant="body1" lineHeight={1.2}>
              Email:
            </Typography>
            <Typography color="text.secondary" variant="body1" lineHeight={1.2}>
              {email}
            </Typography>
          </Container>
        )}

        {name && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Typography variant="body1" lineHeight={1.2}>
              Account Holder Name:
            </Typography>
            <Typography color="text.secondary" variant="body1" lineHeight={1.2}>
              {name}
            </Typography>
          </Container>
        )}

        {bank_account_number && (
          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography variant="body1" lineHeight={1.2}>
              Bank Account Number:
            </Typography>
            <Typography color="text.secondary" variant="body1" lineHeight={1}>
              {bank_account_number}
            </Typography>
          </Container>
        )}

        {ifsc && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Typography variant="body1" lineHeight={1}>
              IFSC Code:
            </Typography>
            <Typography color="text.secondary" variant="body1" lineHeight={1}>
              {ifsc}
            </Typography>
          </Container>
        )}

        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" lineHeight={1.2}>
            Relationship:
          </Typography>
          <Typography color="text.secondary" variant="body1" lineHeight={1}>
            {relationship}
          </Typography>
        </Container>

        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: 0,
            alignItems: "center",
            position: "relative",
          }}
        >
          <Link
            to={`/transaction/create/${phone}`}
            style={{
              ...(is_bank_added !== 1 && { pointerEvents: "none" }),
            }}
          >
            <Button
              color="primary"
              variant="contained"
              sx={{
                mt: 2,
                borderRadius: 2,
                bgcolor: "#00796b",
                "&:hover": {
                  bgcolor: "#004d40",
                },
                "@media (max-width: 375px)": {
                  width: "100%",
                },
              }}
              disabled={is_bank_added !== 1}
            >
              <Typography textAlign={"center"} color={"#FFF"}>
                Start Transfer
              </Typography>
            </Button>
          </Link>
        </Container>
      </Box>
      <ToastContainer hideProgressBar position="top-center" />
    </Box>
  );
};

export default RecipientsCard;
