import React, { useEffect, useState } from "react";
import { Container, Box, Typography } from "@mui/material";
import "../App.css";
import Footer from "../components/footer";
import HomeContent from "../components/homeContent";
import Nav from "../components/nav";
import { getUTMParams } from "../helper/helper";
import { App } from "@capacitor/app";

const Home = () => {
  const [version, setVersion] = useState();
  useEffect(() => {
    App.getInfo()
      .then((info) => {
        setVersion(info.version);
      })
      .catch((e) => {
        console.log(e);
      });
    const utmParams = getUTMParams();
    if (Object.keys(utmParams).length > 0) {
      localStorage.setItem("utmParams", JSON.stringify(utmParams));
    }
  }, []);
  return (
    <Box sx={{ position: "relative" }}>
      <Nav />
      <Box sx={{ bgcolor: "brandLight.main" }}>
        <Container className="homeContentContainer">
          <HomeContent />
        </Container>
      </Box>
      <Footer />
      <Box bgcolor={"white"} p={2}>
        <Typography textAlign={"center"} variant="h6">
          Built by Ex-Amazonians v{version}
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;
