import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../helper/helper";
import { backend } from "./userReducer";

const apiRoutes = {
  banks: `${backend}/getbit/banks/IND`,
  env: `${backend}/frontEnv`,
  banner: `${backend}/banner`,
  appVersion: `${backend}/appVersion`,
};
const resourceReducer = createSlice({
  name: "resource",
  initialState: {
    loading: false,
    bankList: localStorage.getItem("banks")
      ? JSON.parse(localStorage.getItem("banks"))
      : [],
    envList: null,
    bannerData: localStorage.getItem("banner")
      ? JSON.parse(localStorage.getItem("banner"))
      : "",
    versionData: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBanks.fulfilled, (state, action) => {
        state.loading = false;
        state.bankList = action.payload;
      })
      .addCase(getBanks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBanks.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchEnv.fulfilled, (state, action) => {
        state.loading = false;
        state.envList = action.payload;
      })
      .addCase(fetchEnv.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchEnv.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(bannerData.fulfilled, (state, action) => {
        state.loading = false;
        state.bannerData = action.payload;
      })
      .addCase(bannerData.rejected, (state, action) => {
        state.loading = false;
        state.bannerData = action.payload;
      })
      .addCase(bannerData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(appVersion.fulfilled, (state, action) => {
        state.loading = false;
        state.versionData = action.payload;
      });
  },
});

export const getBanks = createAsyncThunk(
  "resource/bank",
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      let config = {
        method: "get",
        url: apiRoutes.banks,
        headers: { "x-auth-token": token },
      };
      const payload = await axios.request(config);
      localStorage.setItem("banks", JSON.stringify(payload.data));
      return payload.data;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const fetchEnv = createAsyncThunk(
  "resource/env",
  async (_, { rejectWithValue }) => {
    try {
      let config = {
        method: "get",
        url: apiRoutes.env,
      };
      const payload = await axios.request(config);
      return payload.data;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const bannerData = createAsyncThunk(
  "resource/banner",
  async (_, { rejectWithValue }) => {
    try {
      let config = {
        method: "get",
        url: apiRoutes.banner,
      };
      const payload = await axios.request(config);
      localStorage.setItem("banner", JSON.stringify(payload.data));
      return payload.data;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const appVersion = createAsyncThunk(
  "resource/appVersion",
  async (params, { rejectWithValue }) => {
    let platform = ''
    switch(params.platform){
      case "ios":
        platform = 'iOS'
        break;
      case "android":
        platform = 'Android'
        break;
      default:
        break;
    }
    try {
      let config = {
        method: "get",
        url: apiRoutes.appVersion + `?platform=${platform}`,
      };
      const payload = await axios.request(config);
      return payload.data;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export default resourceReducer.reducer;
