import { Box } from '@mui/material';
import React from 'react'

const BottomBranding = () => {
  return (
    <Box sx={{ m: 10 }}>
      {/* <Typography variant="subtitle1" textAlign="center" color="textSecondary">
        CROBO V{getAppVersion() ? getAppVersion() : "1.0.2"}
      </Typography> */}
    </Box>
  );
}

export default BottomBranding