import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { pulseAnimation } from '../styles/animations';

const Loader = ({color}) => {
  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      py={3}
      sx={pulseAnimation}
    >
      <CircularProgress color="brand" />
    </Box>
  );
}

export default Loader;