import React, { useEffect, useState } from "react";
import "./App.css";
import router from "./route";
import { Box, createTheme, ThemeProvider, Typography } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bannerData, fetchEnv, appVersion } from "./reducers/resourceReducer";
import RouteChangeTracker from "./components/RouteChangeTracker";
import { analytics, errorAnalytics } from "./helper/helper";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { VersionInfo } from "./version";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { isWeb } from "./utils/platform";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";

const theme = createTheme({
  palette: {
    brand: {
      main: "#15ad87",
      dark: "#129b79",
    },
    brandDark: {
      main: "#36454F",
      text: "white",
    },
    brandLight: {
      main: "#EBF4F6",
      text: "black",
    },
  },
});

const Auth = () => {
  const dispatch = useDispatch();
  const _appVersion = useSelector((state) => state.resource.versionData);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [version, setVersion] = useState();
  useEffect(() => {
    App.getInfo()
      .then((info) => {
        setVersion(info.version);
      })
      .catch((e) => {
        console.log(e);
      });
    const initializeApp = async () => {
      try {
        dispatch(fetchEnv());
        dispatch(bannerData());
        dispatch(appVersion({ platform: Capacitor.getPlatform() }));
        if (parseInt(version) < parseFloat(_appVersion.appVersion)) {
          setIsUpdateAvailable(false);
        }
        await ScreenOrientation.lock({ orientation: "portrait" });
      } catch (error) {
        errorAnalytics("Error initializing app", { error });
      }
    };

    initializeApp();

    // Cleanup function to unlock orientation when component unmounts
    return () => {
      const unlockOrientation = async () => {
        try {
          await ScreenOrientation.unlock();
        } catch (error) {
          console.error("Error unlocking screen orientation:", error);
        }
      };
      unlockOrientation();
    };
  }, [dispatch]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "90%",
    bgcolor: "white",
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={isUpdateAvailable} aria-labelledby="update-app">
        <Box
          sx={style}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            id="update-app"
            variant="h6"
            fontWeight={600}
            textAlign="center"
          >
            Please update the app to continue
          </Typography>
          <Button
            variant="contained"
            size="large"
            color="brand"
            sx={{ mt: 2 }}
            onClick={() => {
              switch (Capacitor.getPlatform()) {
                case "ios":
                  window.open(
                    "https://apps.apple.com/us/app/crobo/id6474628320",
                    "_blank"
                  );
                  analytics.track("App Store Button Clicked", {
                    category: "Update App",
                    label: "App Store Link",
                    url: "https://apps.apple.com/us/app/crobo/id6474628320",
                  });
                  break;
                case "android":
                  window.open(
                    "https://play.google.com/store/apps/details?id=io.ionic.CROBO",
                    "_blank"
                  );
                  analytics.track("Google Play Button Clicked", {
                    category: "Update App",
                    label: "Google Play Link",
                    url: "https://play.google.com/store/apps/details?id=io.ionic.CROBO",
                  });
                  break;
              }
            }}
          >
            <Typography color={"white"}>UPDATE</Typography>
          </Button>
        </Box>
      </Modal>
      <RouterProvider
        router={router}
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}
      >
        <RouteChangeTracker />
      </RouterProvider>
      <VersionInfo />
    </ThemeProvider>
  );
};

export default Auth;
