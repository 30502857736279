import { Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { logout } from "../../reducers/userReducer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  auth,
  signOut,
} from "../../service/firebase";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { isWeb } from "../../utils/platform";

const options = [
  { name: "Home", path: "/" },
  { name: "Start Transfer", path: "/transaction" },
  { name: "Profile", path: "/profile" },
  { name: "Transaction History", path: "/transaction/history" },
  { name: "About Us", path: "/about-us" },
  { name: "Refer & Earn", path: "/referrals" },
  { name: "Support", path: "/support" },
];

const NavMenu = ({ logoutPageList, setAnchorElUser, anchorElUser }) => {
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
  const handleLogout = async (dispatch, navigate, handleCloseUserMenu) => {
    handleCloseUserMenu();
    dispatch(logout());
    isWeb() ? signOut(auth) : await FirebaseAuthentication.signOut();
    navigate("/login");
  };
  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      {!logoutPageList.includes(location.pathname) &&
        options.map((o) => (
          <Link
            key={o.name}
            to={`${o.path}`}
            style={{ textDecoration: "none" }}
          >
            <MenuItem key={o.name} onClick={handleCloseUserMenu}>
              <Typography
                textAlign="center"
                variant="h6"
                color={"black"}
                style={{ textDecoration: "none" }}
              >
                {o.name}
              </Typography>
            </MenuItem>
          </Link>
        ))}

      <MenuItem
        key={"logout"}
        onClick={() => handleLogout(dispatch, navigate, handleCloseUserMenu)}
      >
        <Typography
          textAlign="center"
          variant="h6"
          color={"black"}
          style={{ textDecoration: "none" }}
        >
          Logout
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default NavMenu;
