import React, { useState } from "react";
import CroboContainer from "../../components/croboContainer";
import { Box, TextField, Button, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { register } from "../../reducers/userReducer";
import { useSelector } from "react-redux";

const NewUser = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [referral, setReferral] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { pin } = useSelector((state) => state.userAuth);

  const validate = () => {
    let tempErrors = {};
    if (!name) tempErrors.name = "Name is required";
    if (!email) tempErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(email))
      tempErrors.email = "Email is not valid";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async () => {
    const phone = localStorage.getItem("phone");
    if (validate()) {
      await register({
        client_id: "APIGetBitRemitWeb",
        unverified_user_name: name,
        unverified_email: email,
        referral_code: referral,
        country_code: "+1",
        phone_number: phone.substring(2),
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        referred_user_id: "",
      });
      if (!pin) {
        navigate("/pin/set");
      } else if (pin) {
        navigate("/pin");
      }
    }
  };

  return (
    <CroboContainer>
      <Container>
        <Typography
          sx={{ paddingBottom: 2, width: "100%", marginTop: 4 }}
          variant="h5"
        >
          Enter Personal Info
        </Typography>
        <Box
          component="form"
          display={"flex"}
          flexDirection={"column"}
          noValidate
          autoComplete="off"
        >
          <TextField
            required
            id="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
            sx={{ marginY: 1 }}
          />
          <TextField
            required
            id="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            sx={{ marginY: 1 }}
            helperText={errors.email}
          />
          <TextField
            id="referral"
            label="Referral Code/ SignUp Code"
            sx={{ marginY: 1 }}
            value={referral}
            onChange={(e) => setReferral(e.target.value)}
          />
          <Button
            variant="contained"
            color="brand"
            size="large"
            sx={{ marginY: 1 }}
            onClick={handleSubmit}
          >
            Proceed
          </Button>
        </Box>
      </Container>
    </CroboContainer>
  );
};

export default NewUser;
