import { keyframes } from '@mui/material/styles';

// Keyframe animations
export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const slideUp = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

// Reusable animation styles
export const fadeInAnimation = {
  animation: `${fadeIn} 0.3s ease-out`
};

export const slideUpAnimation = {
  animation: `${slideUp} 0.4s ease-out`
};

export const pulseAnimation = {
  animation: `${pulse} 1s ease-in-out infinite`
};

// Transition presets
export const buttonTransition = {
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
  },
  '&:active': {
    transform: 'translateY(0)'
  }
};

export const cardTransition = {
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 6px 12px rgba(0,0,0,0.1)'
  }
};

export const iconTransition = {
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'scale(1.1)'
  }
}; 