import React, { useEffect, useState } from "react";
import CroboContainer from "../../components/croboContainer";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Popover,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import { useDispatch, useSelector } from "react-redux";
import { getBanks } from "../../reducers/resourceReducer";
import CodeIcon from "@mui/icons-material/Code";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PeopleIcon from '@mui/icons-material/People';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
  callPreregister,
  resetPreregister,
} from "../../reducers/preRegisterReducer";
import { useNavigate } from "react-router-dom";
import { analytics } from "../../helper/helper";
import { callFetchUsers } from "../../reducers/userReducer";
import ApartmentIcon from '@mui/icons-material/Apartment';

const CONSTANTS = {
  API: {
    CLIENT_ID: "APIGetBitRemitWeb",
    COUNTRY_CODE: "91",
    COUNTRY_CODE_WITH_PLUS: "+91",
    STATUS_SUCCESS: 200,
  },
  VALIDATION: {
    EMAIL_REGEX: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    PHONE_LENGTH: 10,
  },
  STYLES: {
    COLORS: {
      WHITE: "white",
      RED: "red",
      BLACK: "black",
    },
    SPACING: {
      PADDING_TOP: 4,
      PADDING_BOTTOM: 2,
      MARGIN_TOP: 2,
      POPOVER_PADDING: 2,
    },
    WIDTH: {
      FULL: "100%",
    },
  },
  MESSAGES: {
    ERRORS: {
      ALL_FIELDS_REQUIRED: "All fields are compulsory",
      ACCOUNT_NUMBERS_MISMATCH:
        "Bank Account Number and Confirm Bank Account Number do not match",
      SELECT_BANK: "Please Select Bank",
      NAME_REQUIRED: "Nick name is required",
      ACCOUNT_HOLDER_NAME_REQUIRED: "Account Holder Name is required",
      EMAIL_REQUIRED: "Email is required",
      INVALID_EMAIL: "Invalid email address",
      INVALID_PHONE: "Invalid phone number. It should be exactly 10 digits.",
      INVALID_INDIAN_NUMBER: "Enter a valid Indian number",
      RELATIONSHIP_REQUIRED: "Relationship is mandatory",
    },
    TOOLTIPS: {
      ACCOUNT_HOLDER: "Recipient name on the bank account",
      NICKNAME: "Add a nick name to refer to this person",
    },
    PLACEHOLDERS: {
      BANK_ACCOUNT: "Enter Account Number",
      CONFIRM_ACCOUNT: "Confirm Account Number",
      IFSC: "IFSC Code",
      ACCOUNT_HOLDER: "Account holder name",
      NICKNAME: "Nickname",
      EMAIL: "Email",
      PHONE: "Phone (Optional)",
      RELATIONSHIP: "Select Relationship",
    },
    HEADINGS: {
      BANK_DETAILS: "Enter recipient bank details",
      RECIPIENT_DETAILS: "Enter Recipient Details",
    },
    INFO: {
      ACCOUNT_TYPES:
        "Only NRO, Savings and Current accounts are supported. \nNRE is not supported.",
    },
  },
  RELATIONSHIPS: {
    MYSELF: "Myself",
    FAMILY: "My Family",
    OTHER: "Someone else",
  },
  ANALYTICS: {
    EVENTS: {
      BANK_DETAILS_FAILED: "Recipient Bank Details Submission Failed",
      BANK_DETAILS_SUCCESS: "Recipient Bank Details Submitted",
      PREREGISTER_RESPONSE: "Preregister Response",
      PREREGISTER_ERROR: "Preregister Error",
      NAME_FAILED: "Recipient Name Submission Failed",
      EMAIL_FAILED: "Recipient Email Submission Failed",
      TRANSFER_WITHOUT_PHONE: "Transfer Started without Phone",
      INVALID_PHONE: "Invalid Phone Number Entered",
      RELATIONSHIP_FAILED: "Recipient Relationship Submission Failed",
      USER_FOUND: "Existing User Found, Redirect to Create Transaction",
      USER_ERROR: "Error in Fetching User",
    },
  },
};

const randomPhoneGenerator = (senderPhone) => {
  const number = Math.floor(Math.random() * 90000) + 10000;
  return senderPhone.substring(7) + number;
}

const phonePrefix = (prefix, phone) => {
  if (!prefix) return phone;
  return `${prefix}-${phone}`;
}

const RecipientDetails = () => {
  const [bankScreen, setBankScreen] = useState(false);
  const [bankName, setBankName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [confirmBankAccountNumber, setConfirmBankAccountNumber] = useState("");
  const [IFSC, setIFSC] = useState("");
  const [name, setName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [email, setEmail] = useState("");
  const [relationship, setRelationship] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { bankList } = useSelector((state) => state.resource);
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { preRegisterDetails } = useSelector((state) => state.preRegister);
  const profile = localStorage.getItem('profile');
  const userId = JSON.parse(profile || {}).user_id;
  const senderPhone = localStorage.getItem('phone');

  // Separate state for each popover
  const [anchorElName, setAnchorElName] = useState(null);
  const [anchorElNickname, setAnchorElNickname] = useState(null);

  useEffect(() => {
    dispatch(resetPreregister());
    dispatch(getBanks());
  }, [dispatch, preRegisterDetails, phone, navigate]);

  const validateEmail = (email) => {
    return CONSTANTS.VALIDATION.EMAIL_REGEX.test(String(email).toLowerCase());
  };

  const handleRecipient = async () => {
    if (!bankName || !bankAccountNumber || !confirmBankAccountNumber || !IFSC || !name) {
      setError(CONSTANTS.MESSAGES.ERRORS.ALL_FIELDS_REQUIRED);
      analytics.track(CONSTANTS.ANALYTICS.EVENTS.BANK_DETAILS_FAILED, {
        reason: "Missing Fields",
      });
      return;
    }
    if (bankAccountNumber !== confirmBankAccountNumber) {
      setError(CONSTANTS.MESSAGES.ERRORS.ACCOUNT_NUMBERS_MISMATCH);
      analytics.track(CONSTANTS.ANALYTICS.EVENTS.BANK_DETAILS_FAILED, {
        reason: "Account Number Mismatch",
      });
      return;
    }

    if (!bankName) {
      setError(CONSTANTS.MESSAGES.ERRORS.SELECT_BANK);
      analytics.track(CONSTANTS.ANALYTICS.EVENTS.BANK_DETAILS_FAILED, {
        reason: "No Bank Selected",
      });
      return;
    }
    setError(null);

    analytics.track(CONSTANTS.ANALYTICS.EVENTS.BANK_DETAILS_SUCCESS, {
      name,
      email,
      bankName,
      bankAccountNumber,
      IFSC,
    });

    try {
      const prefixedPhone = phonePrefix(userId, phone || randomPhoneGenerator(senderPhone));
      const preRegisterResponse = await callPreregister({
        phone_number: prefixedPhone,
        user_name: name,
        client_id: CONSTANTS.API.CLIENT_ID,
        country_code: CONSTANTS.API.COUNTRY_CODE,
        email: email,
        bank_details: {
          bank_account_number: bankAccountNumber,
          bank_name: bankName,
          ifsc_code: IFSC,
          account_holder_name: accountHolderName,
        },
        relationship
      });
      analytics.track(CONSTANTS.ANALYTICS.EVENTS.PREREGISTER_RESPONSE, preRegisterResponse);
      navigate(`/transaction/create/${prefixedPhone}`);
    } catch (error) {
      analytics.track(CONSTANTS.ANALYTICS.EVENTS.PREREGISTER_ERROR, error);
      navigate(`/transaction`);
    }
  };

  const handleProceedToBankDetails = async () => {
    if (!name) {
      setError(CONSTANTS.MESSAGES.ERRORS.NAME_REQUIRED);
      analytics.track(CONSTANTS.ANALYTICS.EVENTS.NAME_FAILED, {
        reason: "Missing Name",
      });
      return;
    }
    if (!accountHolderName) {
      setError(CONSTANTS.MESSAGES.ERRORS.ACCOUNT_HOLDER_NAME_REQUIRED);
      return;
    }
    if (!email) {
      setError(CONSTANTS.MESSAGES.ERRORS.EMAIL_REQUIRED);
      analytics.track(CONSTANTS.ANALYTICS.EVENTS.EMAIL_FAILED, {
        reason: "Missing Email",
      });
      return;
    }
    if (!validateEmail(email)) {
      setError(CONSTANTS.MESSAGES.ERRORS.INVALID_EMAIL);
      analytics.track(CONSTANTS.ANALYTICS.EVENTS.EMAIL_FAILED, {
        reason: "Invalid Email",
      });
      return;
    }
    let receiverPhone = phone;
    if (!receiverPhone) {
      setError(null);
      receiverPhone = randomPhoneGenerator(senderPhone);
      analytics.track(CONSTANTS.ANALYTICS.EVENTS.TRANSFER_WITHOUT_PHONE, { receiverPhone });
    } else {
      if (receiverPhone.length !== CONSTANTS.VALIDATION.PHONE_LENGTH || isNaN(receiverPhone)) {
        setError(CONSTANTS.MESSAGES.ERRORS.INVALID_PHONE);
        analytics.track(CONSTANTS.ANALYTICS.EVENTS.INVALID_PHONE, { receiverPhone });
        return;
      } else if (receiverPhone === senderPhone.slice(2)) {
        setError(CONSTANTS.MESSAGES.ERRORS.INVALID_INDIAN_NUMBER);
        return;
      } else if (error) {
        setError(null);
      }
    }
    if (!relationship) {
      setError(CONSTANTS.MESSAGES.ERRORS.RELATIONSHIP_REQUIRED);
      analytics.track(CONSTANTS.ANALYTICS.EVENTS.RELATIONSHIP_FAILED, {
        reason: "Invalid Relationship",
      });
      return;
    }
    try {
      const prefixedPhone = phonePrefix(userId, receiverPhone);
      setLoading(true);
      const { data } = await callFetchUsers({
        client_id: CONSTANTS.API.CLIENT_ID,
        country_code: CONSTANTS.API.COUNTRY_CODE_WITH_PLUS,
        phone_number: prefixedPhone,
      });
      if (data.status_code === CONSTANTS.API.STATUS_SUCCESS) {
        navigate(`/transaction/create/${prefixedPhone}`);
        analytics.track(CONSTANTS.ANALYTICS.EVENTS.USER_FOUND, { phone });
      } else {
        setError(null);
        setLoading(false);
        setBankScreen(true);
      }
    } catch (error) {
      analytics.track(CONSTANTS.ANALYTICS.EVENTS.USER_ERROR, { phone, error });
    }
  };

  const handlePopoverOpen = (setAnchorEl) => (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (setAnchorEl) => () => {
    setAnchorEl(null);
  };

  return (
    <CroboContainer>
      <Container>
        {bankScreen ? (
          <Box
            paddingTop={CONSTANTS.STYLES.SPACING.PADDING_TOP}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{
                paddingBottom: CONSTANTS.STYLES.SPACING.PADDING_BOTTOM,
                width: CONSTANTS.STYLES.WIDTH.FULL,
              }}
              variant="h5"
            >
              {CONSTANTS.MESSAGES.HEADINGS.BANK_DETAILS}
            </Typography>
            {error && (
              <Typography
                color={CONSTANTS.STYLES.COLORS.RED}
                textAlign={"center"}
              >
                {error}
              </Typography>
            )}
            <FormControl
              startAdornment={
                <InputAdornment position="start">
                  <AccountBalanceIcon />
                </InputAdornment>
              }
              sx={{
                width: CONSTANTS.STYLES.WIDTH.FULL,
                bgcolor: CONSTANTS.STYLES.COLORS.WHITE,
              }}
            >
              <Autocomplete
                disablePortal
                options={bankList}
                onChange={(event, newValue) => {
                  setBankName(newValue);
                }}
                renderOption={(props, option) => (
                  <Typography {...props}>
                    {option.toUpperCase()}
                  </Typography>
                )}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    label="Bank Name"
                    sx={{
                      bgcolor: CONSTANTS.STYLES.COLORS.WHITE,
                      '& .MuiOutlinedInput-root': {
                        paddingLeft: '0px',
                        '& fieldset': {
                          border: '1px solid rgba(0, 0, 0, 0.23)',
                        }
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position="start" sx={{paddingLeft: "14px"}}>
                            <ApartmentIcon />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </FormControl>
            <OutlinedInput
              placeholder={CONSTANTS.MESSAGES.PLACEHOLDERS.BANK_ACCOUNT}
              value={bankAccountNumber}
              sx={{
                marginTop: CONSTANTS.STYLES.SPACING.MARGIN_TOP,
                width: CONSTANTS.STYLES.WIDTH.FULL,
                bgcolor: CONSTANTS.STYLES.COLORS.WHITE,
              }}
              startAdornment={
                <InputAdornment position="start">
                  <AccountBalanceIcon />
                </InputAdornment>
              }
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^0-9]/g, '');
                setBankAccountNumber(numericValue);
              }}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*'
              }}
            />
            <OutlinedInput
              placeholder={CONSTANTS.MESSAGES.PLACEHOLDERS.CONFIRM_ACCOUNT}
              value={confirmBankAccountNumber}
              type="password"
              sx={{
                marginTop: CONSTANTS.STYLES.SPACING.MARGIN_TOP,
                width: CONSTANTS.STYLES.WIDTH.FULL,
                bgcolor: CONSTANTS.STYLES.COLORS.WHITE,
              }}
              startAdornment={
                <InputAdornment position="start">
                  <AccountBalanceIcon />
                </InputAdornment>
              }
              onChange={(e) => setConfirmBankAccountNumber(e.target.value)}
            />
            <OutlinedInput
              placeholder={CONSTANTS.MESSAGES.PLACEHOLDERS.IFSC}
              value={IFSC}
              sx={{
                marginTop: CONSTANTS.STYLES.SPACING.MARGIN_TOP,
                width: CONSTANTS.STYLES.WIDTH.FULL,
                bgcolor: CONSTANTS.STYLES.COLORS.WHITE,
              }}
              startAdornment={
                <InputAdornment position="start">
                  <CodeIcon />
                </InputAdornment>
              }
              onChange={(e) => {
                let value = e.target.value;
                setIFSC(value.toUpperCase());
              }}
              inputProps={{
                maxLength: 11
              }}
            />
            <Box width={CONSTANTS.STYLES.WIDTH.FULL}>
              <Typography marginTop={CONSTANTS.STYLES.SPACING.MARGIN_TOP}>
                {CONSTANTS.MESSAGES.INFO.ACCOUNT_TYPES}
              </Typography>
            </Box>
            <Button
              color="brand"
              variant="contained"
              sx={{
                marginTop: CONSTANTS.STYLES.SPACING.MARGIN_TOP,
                width: CONSTANTS.STYLES.WIDTH.FULL,
              }}
              size="large"
              onClick={handleRecipient}
            >
              <Typography variant="h6" color={CONSTANTS.STYLES.COLORS.BLACK}>
                Proceed
              </Typography>
            </Button>
          </Box>
        ) : (
          <Box
            paddingTop={CONSTANTS.STYLES.SPACING.PADDING_TOP}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ paddingBottom: 0, width: CONSTANTS.STYLES.WIDTH.FULL }}
              variant="h5"
            >
              {CONSTANTS.MESSAGES.HEADINGS.RECIPIENT_DETAILS}
            </Typography>
            {error && (
              <Typography
                color={CONSTANTS.STYLES.COLORS.RED}
                textAlign={"center"}
              >
                {error}
              </Typography>
            )}
            <OutlinedInput
              placeholder={CONSTANTS.MESSAGES.PLACEHOLDERS.ACCOUNT_HOLDER}
              value={accountHolderName}
              sx={{
                marginTop: CONSTANTS.STYLES.SPACING.MARGIN_TOP,
                width: CONSTANTS.STYLES.WIDTH.FULL,
                bgcolor: CONSTANTS.STYLES.COLORS.WHITE,
              }}
              startAdornment={
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              }
              endAdornment={
                <InfoIcon
                  sx={{ cursor: "pointer" }}
                  onClick={handlePopoverOpen(setAnchorElName)}
                />
              }
              onChange={(e) => setAccountHolderName(e.target.value)}
            />
            <Popover
              open={Boolean(anchorElName)}
              anchorEl={anchorElName}
              onClose={handlePopoverClose(setAnchorElName)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Typography sx={{ p: CONSTANTS.STYLES.SPACING.POPOVER_PADDING }}>
                {CONSTANTS.MESSAGES.TOOLTIPS.ACCOUNT_HOLDER}
              </Typography>
            </Popover>
            <OutlinedInput
              placeholder={CONSTANTS.MESSAGES.PLACEHOLDERS.NICKNAME}
              value={name}
              sx={{
                marginTop: CONSTANTS.STYLES.SPACING.MARGIN_TOP,
                width: CONSTANTS.STYLES.WIDTH.FULL,
                bgcolor: CONSTANTS.STYLES.COLORS.WHITE,
              }}
              startAdornment={
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              }
              endAdornment={
                <InfoIcon
                  sx={{ cursor: "pointer" }}
                  onClick={handlePopoverOpen(setAnchorElNickname)}
                />
              }
              onChange={(e) => setName(e.target.value)}
            />
            <Popover
              open={Boolean(anchorElNickname)}
              anchorEl={anchorElNickname}
              onClose={handlePopoverClose(setAnchorElNickname)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Typography sx={{ p: CONSTANTS.STYLES.SPACING.POPOVER_PADDING }}>
                {CONSTANTS.MESSAGES.TOOLTIPS.NICKNAME}
              </Typography>
            </Popover>
            <OutlinedInput
              placeholder={CONSTANTS.MESSAGES.PLACEHOLDERS.EMAIL}
              value={email}
              sx={{
                marginTop: CONSTANTS.STYLES.SPACING.MARGIN_TOP,
                width: CONSTANTS.STYLES.WIDTH.FULL,
                bgcolor: CONSTANTS.STYLES.COLORS.WHITE,
              }}
              startAdornment={
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              }
              onChange={(e) => setEmail(e.target.value)}
            />
            <OutlinedInput
              placeholder={CONSTANTS.MESSAGES.PLACEHOLDERS.PHONE}
              value={phone}
              sx={{
                marginTop: CONSTANTS.STYLES.SPACING.MARGIN_TOP,
                width: CONSTANTS.STYLES.WIDTH.FULL,
                bgcolor: CONSTANTS.STYLES.COLORS.WHITE,
              }}
              startAdornment={
                <InputAdornment position="start">
                  {CONSTANTS.API.COUNTRY_CODE_WITH_PLUS}
                </InputAdornment>
              }
              onChange={(e) => {
                setPhone(e.target.value);
                if (e.target.value === senderPhone.slice(2)) {
                  setError(CONSTANTS.MESSAGES.ERRORS.INVALID_INDIAN_NUMBER);
                  analytics.track(CONSTANTS.ANALYTICS.EVENTS.INVALID_PHONE, {
                    phone: e.target.value,
                  });
                } else if (error) {
                  setError(null);
                }
              }}
            />
            <Select
              id="relationship"
              value={relationship}
              sx={{
                marginTop: CONSTANTS.STYLES.SPACING.MARGIN_TOP,
                width: CONSTANTS.STYLES.WIDTH.FULL,
                bgcolor: CONSTANTS.STYLES.COLORS.WHITE,
              }}
              displayEmpty
              startAdornment={
                <InputAdornment position="start">
                  <PeopleIcon />
                </InputAdornment>
              }
              onChange={(e) => setRelationship(e.target.value)}
              renderValue={(selected) => {
                if (selected === "") {
                  return <>{CONSTANTS.MESSAGES.PLACEHOLDERS.RELATIONSHIP}</>;
                }
                return selected;
              }}
            >
              <MenuItem value={CONSTANTS.RELATIONSHIPS.MYSELF}>
                {CONSTANTS.RELATIONSHIPS.MYSELF}
              </MenuItem>
              <MenuItem value={CONSTANTS.RELATIONSHIPS.FAMILY}>
                {CONSTANTS.RELATIONSHIPS.FAMILY}
              </MenuItem>
              <MenuItem value={CONSTANTS.RELATIONSHIPS.OTHER}>
                {CONSTANTS.RELATIONSHIPS.OTHER}
              </MenuItem>
            </Select>
            <Button
              color="brand"
              variant="contained"
              onClick={handleProceedToBankDetails}
              sx={{
                marginTop: CONSTANTS.STYLES.SPACING.MARGIN_TOP,
                width: CONSTANTS.STYLES.WIDTH.FULL,
              }}
              size="large"
              disabled={loading}
            >
              <Typography
                variant="h6"
                color={CONSTANTS.STYLES.COLORS.BLACK}
                display={"flex"}
                gap={2}
                alignItems={"center"}
              >
                {loading ? (
                  <CircularProgress color="brand" size={"small"} />
                ) : null}
                Proceed
              </Typography>
            </Button>
          </Box>
        )}
      </Container>
    </CroboContainer>
  );
};

export default RecipientDetails;
