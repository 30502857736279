import { Client } from "persona";

const analytics = window.analytics;
export const runPersonaClient = async (
  inquiryId,
  onPersonaComplete,
  onPersonaCancel
) => {
  const client = new Client({
    inquiryId: inquiryId,
    onComplete: ({ inquiryId, status, fields }) => {
      onPersonaComplete();
      analytics.track(`Persona Complete`, {
        inquiryId,
        status,
        fields,
      });
    },
    onCancel: ({ inquiryId, sessionToken }) => {
      console.log("on cancel");
      analytics.track("Persona Cancel", { inquiryId, sessionToken });
      onPersonaCancel();
      client.destroy();
    },
    onError: (error) => {
      console.log(error);
      analytics.track("Persona Error", { error });
    },
    onEvent: (name, meta) => {
      switch (name) {
        case "start":
          analytics.track(`Persona Event: Start`, { ...meta });
          break;
        case "incomplete":
          analytics.track(`Persona Event: Incomplete`, { ...meta });
          onPersonaComplete(); // Dispatch remitStatusUpdate when incomplete event hits
          break;
        case "exit":
          console.log("on exit");
          analytics.track("Persona Event: Exit", { ...meta });
          break;
        case "error":
          analytics.track(`Persona Event: Error`, { ...meta });
          onPersonaComplete();
          client.destroy();
          window.location.reload();
          break;
        case "load":
          analytics.track(`Persona Event: Load`, { ...meta });
          break;
        case "ready":
          analytics.track(`Persona Event: Ready`, { ...meta });
          break;
        case "complete":
          analytics.track(`Persona Event: Complete`, { ...meta });
          break;
        case "fail":
          analytics.track(`Persona Event: Fail`, { ...meta });
          break;
        case "document-camera-select":
          analytics.track(`Persona Event: Document Camera Select`, { ...meta });
          break;
        case "document-camera-capture":
          analytics.track(`Persona Event: Document Camera Capture`, {
            ...meta,
          });
          break;
        case "document-upload":
          analytics.track(`Persona Event: Document Upload`, { ...meta });
          break;
        case "one-time-link-sent":
          analytics.track(`Persona Event: One-Time Link Sent`, { ...meta });
          break;
        case "one-time-link-start":
          analytics.track(`Persona Event: One-Time Link Start`, { ...meta });
          break;
        case "one-time-link-exit":
          analytics.track(`Persona Event: One-Time Link Exit`, { ...meta });
          break;
        case "selfie-camera-select":
          analytics.track(`Persona Event: Selfie Camera Select`, { ...meta });
          break;
        case "selfie-camera-capture":
          analytics.track(`Persona Event: Selfie Camera Capture`, { ...meta });
          break;
        case "selfie-record-upload":
          analytics.track(`Persona Event: Selfie Record Upload`, { ...meta });
          break;
        case "load-camera-failed":
          analytics.track(`Persona Event: Load Camera Failed`, { ...meta });
          break;
        case "page-change":
          analytics.track(`Persona Event: Page Change`, { ...meta });
          break;
        case "inquiry-load":
          analytics.track(`Persona Event: Inquiry Load`, { ...meta });
          break;
        default:
          analytics.track(`Persona Event: ${name}`, { ...meta });
      }
    },
  });

  client.open();

  return () => {
    client.cancel(true);
  };
};
