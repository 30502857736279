import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";
import Pin from "./pages/Pin/verifyPin";
import SetPin from "./pages/Pin/setPin";
import TransactionHistory from "./pages/Transaction/transactionHistory";
import TransactionDetails from "./pages/Transaction/transactionDetails";
import Referrals from "./pages/referrals/referral";
import ReferAndEarnFaq from "./pages/referrals/referFAQ";
import Transactions from "./pages/Transaction/transaction";
import { CreateTransaction } from "./pages/Transaction/create";
import Profile from "./pages/profile";
import Onboarding from "./pages/Onboarding";
import RedeemHistory from "./pages/referrals/redeemHistory";
import ReferralHistory from "./pages/referrals/referHistory";
import Confirm from "./pages/Transaction/confirm";
import TransactionComplete from "./pages/Transaction/complete";
import ProtectedRoute from "./components/protectedRoute";
import Home from "./pages/home";
import AboutUs from "./pages/about";
import RecipientDetails from "./pages/Transaction/recipientDetails";
import ManageRecipients from "./pages/Recipients/manageRecipients";
import EditRecipient from "./pages/Recipients/editRecipient";
import Login from "./pages/login";
import NewUser from "./pages/Pin/newUser";
import Support from "./pages/support";
import ReferralProgramTerms from "./pages/condition2";
import ManageBankAccount from "./pages/manageBank";
import TnC from "./pages/TnC";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import LINK_PHONE from "./pages/Pin/linkPhone";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/referrals" element={<Referrals />} />
        <Route path="/referrals/redeem-history" element={<RedeemHistory />} />
        <Route
          path="/referrals/referral-history"
          element={<ReferralHistory />}
        />
        <Route path="/new" element={<NewUser />} />
        <Route path="/link/phone" element={<LINK_PHONE />} />
        <Route path="/pin" element={<Pin />} />
        <Route path="/pin/set" element={<SetPin />} />
        <Route path="/referrals/refer-faq" element={<ReferAndEarnFaq />} />
        <Route path="/transaction/history" element={<TransactionHistory />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/transaction/confirm/:id/:amount" element={<Confirm />} />
        <Route
          path="/transaction/details/:transid"
          element={<TransactionDetails />}
        />
        <Route path="/transaction/complete" element={<TransactionComplete />} />
        <Route path="/transaction/create/new" element={<RecipientDetails />} />
        <Route path="/transaction/create/:id" element={<CreateTransaction />} />
        <Route
          path="/transaction/recipient/:id"
          element={<CreateTransaction />}
        />
        <Route path="/transaction/recipient" element={<RecipientDetails />} />
        <Route path="/transaction" element={<Transactions />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/manage-recipients" element={<ManageRecipients />} />
        <Route path="/edit-recipient" element={<EditRecipient />} />
        <Route path="/manage-bank-account" element={<ManageBankAccount />} />
      </Route>
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/condition2" element={<ReferralProgramTerms />} />
      <Route path="/condition" element={<ReferralProgramTerms />} />

      <Route path="/" element={<Home />} />
      <Route path="/support" element={<Support />} />
      <Route path="/tnc" element={<TnC />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<Navigate to="/transaction" replace />} />
    </Route>
  ),
  {
    future: {
      v7_skipActionErrorRevalidation: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
    },
  }
);

export default router;
