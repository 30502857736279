import { Button } from '@mui/material';
import React from 'react';

const URL = 'https://api.whatsapp.com/send';

const WhatsappContact = ({ number, message, children, onclick }) => {
    number = number?.replace(/[^\w\s]/gi, '').replace(/ /g, '');
    let url = `${URL}`;
    if (message) {
        url += `?text=${encodeURI(message)}`
        return (
          <Button
            onClick={(e) => {
              window.open(url);
              if (onclick) {
                onclick(e);
              }
            }}
            variant="contained"
            sx={{
              borderRadius: 3,
              bgcolor: "brand.main",
              "&:hover": { bgcolor: "brand.dark" },
            }}
          >
            {children}
          </Button>
        );
    }
}

export default WhatsappContact;