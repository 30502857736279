import * as React from "react";
import Box from "@mui/material/Box";
import { ProfileInternal } from "../../pages/profile";
import { Container } from "@mui/material";
import { slideUpAnimation, fadeInAnimation } from "../../styles/animations";

const HamburgerMenu = ({toggleDrawer}) => {
  return (
    <Box
      sx={{ 
        width: "90vw",
        bgcolor: "brandLight.main",
        height: "100vh",
        ...slideUpAnimation,
        ...fadeInAnimation
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <Container sx={{px:2}}>
        <ProfileInternal />
      </Container>
    </Box>
  );
};

export default HamburgerMenu;
