import { Avatar, Box, Typography, Tooltip } from "@mui/material";
import React from "react";
import { ToastContainer } from "react-toastify";
import { analytics, processName } from "../helper/helper";
import { useNavigate } from "react-router-dom";
import Grow from '@mui/material/Grow';

const FrequentContactCard = ({ id, name, phone, email, nickName }) => {
  const navigate = useNavigate();

  const handleTransaction = (e) => {
    e.stopPropagation();
    navigate(`/transaction/create/${phone}`);
    analytics.track("Frequent Contact Selected", {
      name,
      phone,
    });
  };

  return (
    <Grow in={true} timeout={500}>
      <Box
        component="article"
        role="button"
        tabIndex={0}
        sx={{
          p: 2,
          borderRadius: 2,
          mt: 1,
          bgcolor: "white",
          border: "1px solid #e0e0e0",
          cursor: "pointer",
          transition: 'all 0.2s ease-in-out',
          "&:hover": {
            bgcolor: 'grey.50',
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          },
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
        onClick={handleTransaction}
        onKeyPress={(e) => e.key === 'Enter' && handleTransaction(e)}
      >
        <Tooltip title={name || nickName}>
          <Avatar
            sx={{
              bgcolor: "brand.main",
              width: 50,
              height: 50,
              marginX: 2,
              fontSize: '1.2rem',
              fontWeight: 'bold',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
          >
            {processName(nickName).letters}
          </Avatar>
        </Tooltip>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flexGrow: 1,
            ml: 1,
          }}
        >
          <Typography 
            color="text.primary" 
            variant="h6" 
            sx={{ 
              fontWeight: 400,
              fontSize: '1rem',
            }}
          >
            {processName(nickName).words}
          </Typography>
          {/* <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 0.5 
            }}
          >
            {phone.substr(phone.length - 10)}
          </Typography> */}
        </Box>
        
        <ToastContainer hideProgressBar position="top-center" />
      </Box>
    </Grow>
  );
};

export default FrequentContactCard;
