import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { app, firebaseErrorHandler, RecaptchaVerifier ,auth} from "../service/firebase";
import { isWeb } from "../utils/platform";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import { Haptics, ImpactStyle } from '@capacitor/haptics';

export function processName(sentence) {
  const words = sentence?.split(" ");
  const firstTwoWords = words?.slice(0, 2);
  const upperCaseWords = firstTwoWords?.map((word) => word.toUpperCase());
  const firstLetters = upperCaseWords?.map((word) => word.charAt(0));
  const resultLetters = firstLetters?.join("");
  const resultWords = upperCaseWords?.join(" ");

  return {
    letters: resultLetters,
    words: resultWords,
  };
}

export const amountRoundOff = (amount, precision = 2) => {
  const regex = new RegExp(
    "^\\d+(?:\\.\\d{0," + precision.toString() + "})?",
    ""
  );
  if (amount) return Number(amount.toString().match(regex));
  else return 0;
};

export const handleError = async (error) => {
  const response = {
    message: "",
    code: 0,
  };
  if (error.response) {
    response.message = error.response.data.message;
  } else if (error.request) {
    response.message = "No response received from server";
  } else {
    response.message = error.message;
  }
  response.code = error.response.data.response_code;
  analytics.track(`ERROR ${response.message}`, {
    ...error,
  });
  return response;
};

export function matchIsNumeric(text) {
  const isNumber = typeof text === "number";
  const isString = typeof text === "string";
  return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
}

export const handleOpenChat = () => {
  try {
    const profile = localStorage.getItem("profile")
      ? JSON.parse(localStorage.getItem("profile"))
      : null;

    if (profile) {
      window.fcWidget.user.setFirstName(profile.unverified_user_name);
      window.fcWidget.user.setEmail(profile.unverified_email);
      window.fcWidget.user.setProperties({
        phone: localStorage.getItem("phone")
          ? localStorage.getItem("phone")
          : null,
        userId: profile.user_id
      });
    }
    window.fcWidget.open();
  } catch (error) {
    console.log(error);
  }
};
export const errorAnalytics = (message, err = null) => {
  const phone = localStorage.getItem("phone");
  analytics.track(`ERROR ${message}`, {
    ...err,
    phone: phone ? phone : null,
  });
};

export function convertToHourMinute(isoString) {
  const date = new Date(isoString);
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

export function convertToDate(isoString) {
  const d = new Date(isoString);
  const year = d.getUTCFullYear();
  const month = d.getUTCMonth();
  const day = d.getUTCDate();
  return `${day}/${month + 1}/${year}`;
}

export function convertToDateWithTime(isoString) {
  const d = new Date(isoString);
  const year = d.getUTCFullYear();
  const month = d.getUTCMonth();
  const day = d.getUTCDate();
  return `${day}/${month + 1}/${year} ${convertToHourMinute(isoString)}`;
}

export function getUTCTime(localTimeString) {
  // Create a Date object using the provided local time string
  const date = new Date(localTimeString);

  // Extract the UTC components from the Date object
  const utcYear = date.getUTCFullYear();
  const utcMonth = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const utcDay = String(date.getUTCDate()).padStart(2, "0");
  const utcHours = String(date.getUTCHours()).padStart(2, "0");
  const utcMinutes = String(date.getUTCMinutes()).padStart(2, "0");
  const utcSeconds = String(date.getUTCSeconds()).padStart(2, "0");

  // Return the UTC time in the format 'YYYY-MM-DD HH:MM:SS'
  return `${utcYear}-${utcMonth}-${utcDay} ${utcHours}:${utcMinutes}:${utcSeconds}`;
}
const firebaseAnalytics = getAnalytics(app);

/**
 * Analytics helper object to track events.
 */
export const analytics = {
  /**
   * Tracks an event with the given properties.
   * Sends the event to both Segment and Firebase analytics.
   *
   * @param {string} event - The name of the event to track.
   * @param {Object} properties - The properties of the event.
   */
  track: (event, properties= {}) => {
    properties.platform = Capacitor.getPlatform();
    window.analytics.track(event, properties);
    logEvent(firebaseAnalytics, event, properties);
  },
  screen: (name, properties= {}) => {
    properties.platform = Capacitor.getPlatform();
    window.analytics.screen(name, properties);
    logEvent(firebaseAnalytics, "screen_view", {
      screen_name: name,
      ...properties,
    });
  },
  page: (name, properties = {}) => {
    properties.platform = Capacitor.getPlatform();
    window.analytics.page(name, properties);
    logEvent(firebaseAnalytics, "page_view", {
      page_title: name,
      ...properties,
    });
  },
  identify: (userId, traits = {}) => {
    traits.platform = Capacitor.getPlatform();
    window.analytics.identify(userId, traits);
    setUserId(firebaseAnalytics, userId);
    setUserProperties(firebaseAnalytics, traits);
  },
};

export function getUTMParams() {
  const params = new URLSearchParams(window.location.search);
  const utmParams = {};
  [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ].forEach((key) => {
    if (params.has(key)) {
      utmParams[key] = params.get(key);
    }
  });
  return utmParams;
}

export const initializeRecaptcha = () => {
  if (!window.recaptchaVerifier && isWeb()) {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: () => {
          // reCAPTCHA solved
        },
      }
    );
    window.recaptchaVerifier.render().catch((error) => {
      console.error("Recaptcha render failed:", error);
    });
  }
};

export const handlePhoneNumberError = (error, setError, setLoader, setCooldown, phone) => {
  const errorMessage = firebaseErrorHandler(error.code);
  setError(errorMessage);
  setLoader(false);
  analytics.track("OTP Request Failed", { phone, error: errorMessage });
  if (error.code === "auth/too-many-requests") {
    setCooldown(true);
    setTimeout(() => setCooldown(false), 60000);
  }
};

export const handleResendOtp = (phone, setIsResendDisabled, handleSignInWithPhone) => {
  analytics.track("OTP Resent", { phone });
  setIsResendDisabled(true);
  handleSignInWithPhone();
};

export const getAppVersion = () => {
  if(isWeb()) {
    return window.appVersion
  }
  return App.getInfo().then((info) => {
    return info.version
  })
}

/**
 * Trigger haptic feedback on mobile devices
 * @param {string} style - The haptic impact style ('heavy', 'medium', 'light')
 * @returns {Promise<void>}
 */
export const triggerHaptic = async (style = 'heavy') => {
  if (isWeb()) return;
  
  const impactStyle = {
    'heavy': ImpactStyle.Heavy,
    'medium': ImpactStyle.Medium,
    'light': ImpactStyle.Light
  }[style] || ImpactStyle.Heavy;

  try {
    await Haptics.impact({ style: impactStyle });
  } catch (error) {
    console.warn('Haptics not available:', error);
  }
};