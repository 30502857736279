import { Capacitor } from '@capacitor/core';

export const isPlatform = (platform) => {
  return Capacitor.getPlatform() === platform;
};

export const isNative = () => {
  return isPlatform('ios') || isPlatform('android');
};

export const isWeb = () => {
  return isPlatform('web');
}; 