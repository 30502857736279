import React from 'react';
import {
    Container,
    Button,
    Grid,
    Typography,
} from "@mui/material";
import referImage from "../../assets/refer_and_earn.png";
import CroboContainer from "../../components/croboContainer";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from "react-router-dom";

const StepContainer = ({ stepNumber, description }) => (
    <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: { xs: 2, md: 4 },
        padding: { xs: 1, md: 2 }
    }}>
        <Typography variant="h6" sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
            Step {stepNumber}
        </Typography>
        <Typography marginTop={1} sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            {description}
        </Typography>
    </Container>
);

const ReferAndEarnFaq = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    return (
        <>
            <CroboContainer>
                <Container>
                    <Grid justifyContent="center" container marginTop={1} mb={8} spacing={2}>
                        <Grid item xs={12} md={8}>
                            <img src={referImage} width={'100%'} alt="img" style={{ maxWidth: '100%' }} />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <StepContainer stepNumber={1} description="Share your unique code with your friends." />
                            <StepContainer stepNumber={2} description={`They make a transfer of at least $${state.threshold_amount}, you get $${state.referral_amount}.`} />
                            <StepContainer stepNumber={3} description="You can redeem your rewards as an Amazon Gift Card." />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Button variant="contained" fullWidth sx={{
                                marginTop: 4,
                                bgcolor: 'brand.main'
                            }} onClick={() => {
                                navigate('/referrals')
                            }}>
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
                <ToastContainer
                    hideProgressBar
                    position="top-center"
                />
            </CroboContainer>
        </>
    );
}

export default ReferAndEarnFaq;