import {
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { App } from "@capacitor/app";
import brandLogo from "../assets/crobo-white.png";
import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { apiRoutes, logout } from "../reducers/userReducer";
import { processName } from "../helper/helper";
import axios from "axios";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import NavMenu from "./Nav/navMenu";
// import MenuIcon from "@mui/icons-material/Menu";
import HamburgerMenu from "./Nav/hamburgerMenu";

const hideProfile = ["/login", "/signup", "/privacy-policy", "/tnc"];
const logoutPageList = [
  "/pin",
  "/onboarding",
  "/new",
  "/link/phone",
  "/condition",
  "/condition2",
];
const nonProtectedRoute = [
  "/login",
  "/",
  "/signup",
  "/condition",
  "/condition2",
  "/support",
  "/about-us",
  "/privacy-policy",
  "/tnc",
  "/link/phone",
  "/.well-known/assetlinks.json"
];

const Nav = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isSmallMobile = useMediaQuery("(max-width:400px)");
  const location = useLocation();
  const [anchorElUser, setAnchorElUser] = React.useState();
  const [userName, setUserName] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const navigateBack = useCallback(() => {
    if (
      location.pathname === "/transaction/complete" ||
      location.pathname === "/transaction/history"
    ) {
      navigate("/transaction");
    } else {
      navigate(-1);
    }
  }, [location.pathname, navigate]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("token");
      try {
        let config = {
          method: "get",
          url: apiRoutes.profile,
          headers: { "x-auth-token": token },
        };
        const payload = await axios.request(config);
        localStorage.setItem(
          "profile",
          JSON.stringify(payload.data.data.user_profile)
        );
        return payload;
      } catch (error) {
        if (error.response.status !== 200) {
          dispatch(logout());
          if (!nonProtectedRoute.includes(location.pathname)) {
            navigate("/login");
          }
        }
      }
    };

    checkAuth();
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (!nonProtectedRoute.includes(location.pathname)) {
      if (profile) {
        setUserName(processName(profile?.user_name)?.letters);
      } else {
        dispatch(logout());
        navigate("/login");
      }
    }
  }, [dispatch, navigate, location.pathname]);

  useEffect(() => {
    App.addListener("backButton", ({ canGoBack }) => {
      if (canGoBack) {
        navigateBack();
      } else {
        App.exitApp();
      }
    });

    return () => {
      App.removeAllListeners();
    };
  }, [navigateBack]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: "0",
        width: "100%",
        zIndex: 2,
        height: "60px",
      }}
    >
      <Box className='notch'></Box>
      <Grid display={"flex"} sx={{ position: "relative", height: "55px" }}>
        <Grid flexGrow={1}>
          {!nonProtectedRoute.includes(location.pathname) && (
            <IconButton onClick={navigateBack} sx={{ p: 1, zIndex: 1 }}>
              <Avatar sx={{ bgcolor: "brandLight.main" }}>
                <ArrowBackIosNewIcon sx={{ color: "black" }} />
              </Avatar>
            </IconButton>
          )}
        </Grid>

        <Grid
          container
          justifyContent={
            isMobile &&
            location.pathname === "/" &&
            !localStorage.getItem("profile")
              ? "flex-start"
              : "center"
          }
          alignContent={"center"}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bgcolor: "brandLight.main",
            height: "60px",
            padding: "4px",
          }}
        >
          <Link to={"/"}>
            <img
              src={brandLogo}
              alt="brandLogo"
              height={isSmallMobile ? 33 : 45}
            />
          </Link>
        </Grid>
        {location.pathname === "/" && !localStorage.getItem("profile") ? (
          <Grid alignItems={"center"} display={"flex"} paddingY={1}>
            <Link to={"/login"} style={{ textDecoration: "none" }}>
              <Button
                id="sign-in-button"
                variant="outlined"
                color="brand"
                sx={{
                  borderRadius: 2,
                }}
              >
                <Typography variant="body1" color={"black"}>
                  Login
                </Typography>
              </Button>
            </Link>
            <Link to={"/signup"} style={{ textDecoration: "none" }}>
              <Button
                id="sign-in-button"
                variant="contained"
                color="brand"
  
                sx={{
                  marginX: 1 ,
                  borderRadius: 2,
                }}
              >
                <Typography variant="body1" color={"black"}>
                  SignUp
                </Typography>
              </Button>
            </Link>
          </Grid>
        ) : (
          <Grid alignItems={"center"} display={"flex"} padding={1}>
            <Box sx={{ flexGrow: 0 }}>
              {!hideProfile.includes(location.pathname) && (
                <Tooltip title="Open profile">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar sx={{ bgcolor: "brand.main", color: "white" }}>
                      {userName}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              )}
              {/* <Tooltip title="Open profile">
                <IconButton onClick={toggleDrawer(true)} sx={{ p: 0 }}>
                  <MenuIcon sx={{ fontSize: 50 }} />
                </IconButton>
              </Tooltip> */}
              <NavMenu
                anchorElUser={anchorElUser}
                setAnchorElUser={setAnchorElUser}
                logoutPageList={logoutPageList}
              />
            </Box>

            <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
              <HamburgerMenu toggleDrawer={toggleDrawer} />
            </Drawer>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Nav;
