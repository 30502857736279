import {
  Box,
  Container,
  Step,
  StepIcon,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CroboContainer from "../../components/croboContainer";
import { transactionById } from "../../reducers/transactionReducer";
import { amountRoundOff, getUTCTime } from "../../helper/helper";
import Loader from "../../components/loader";
import { SupportButton } from "../support";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PersonIcon from "@mui/icons-material/Person";
import CheckIcon from '@mui/icons-material/Check';
const steps = [
  "Transaction Initiated",
  "Transaction In Process",
  "Transaction Success",
];

const CustomStepIcon = styled(StepIcon)(({ theme }) => ({
  "&.MuiStepIcon-root": {
    color: theme.palette.grey[300],
    width: 28,
    height: 28,
  },
  "&.Mui-completed": {
    color: "#15ad87",
  },
  "&.Mui-active": {
    color: "#fbc02d",
  },
  "&.Mui-error": {
    color: 'red',
  },
}));

const CopyElement = ({ value }) => {
  const [copiedText, setCopiedText] = useState(null);

  const handleCopy = async () => {
    try {
      // Create temporary input element
      const textArea = document.createElement('textarea');
      textArea.value = value;

      // Make it invisible
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);

      // Handle iOS
      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        // Save current selection

        textArea.contentEditable = true;
        textArea.readOnly = false;

        const range = document.createRange();
        range.selectNodeContents(textArea);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }

      // Copy text
      document.execCommand('copy');
      document.body.removeChild(textArea);

      // Show success feedback
      setCopiedText(value);
      setTimeout(() => setCopiedText(null), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography variant="body2" fontWeight={500}>
        {value}
      </Typography>
      {copiedText === value ? (
        <Typography
          variant="caption"
          sx={{
            color: 'success.main',
            display: 'flex',
            alignItems: 'center',
            gap: 0.5
          }}
        >
          <CheckIcon sx={{ fontSize: 16 }} />
          Copied
        </Typography>
      ) : (
        <ContentCopyIcon
          onClick={handleCopy}
          sx={{
            fontSize: 16,
            color: 'text.secondary',
            cursor: 'pointer',
            padding: '4px', // Larger touch target for mobile
            '&:hover': {
              color: 'primary.main',
              transform: 'scale(1.1)'
            },
            transition: 'all 0.2s ease-in-out'
          }}
        />
      )}
    </Box>
  );
};

const TransactionDetails = () => {
  let { transid } = useParams();
  const { transaction, loading } = useSelector((state) => state.transaction);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(transactionById(transid));
  }, [dispatch, transid]);

  const transactionStatus = () => {
    if (transaction?.status === 2) {
      return {
        color: "red",
        status: true,
      };
    }
    if (
      (transaction?.status === 1 && transaction?.receiveInBank === 0) ||
      transaction?.status === 0
    ) {
      return {
        color: "yellow",
        status: false,
      };
    }
    return {
      color: "green",
      status: false,
    };
  };

  const DetailRow = ({ label, value, copyable }) => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        py: 1,
        borderBottom: '1px solid rgba(0,0,0,0.06)',
        '&:last-child': {
          borderBottom: 'none',
        },
      }}
    >
      <Typography color="text.secondary" variant="body2">
        {label}
      </Typography>
      {copyable ? (
        <CopyElement value={value} />
      ) : (
        <Typography variant="body2" fontWeight={500}>
          {value}
        </Typography>
      )}
    </Box>
  );

  function getTransactionTime(index, transaction) {
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC'
    };
    if (index === 0) {
      return new Date(transaction?.createDate).toLocaleString('en-US', options) + ` UTC`;
    } else if (index === 2 && transaction?.status !== 2) {
      let key = `Expected credit date:`;
      if (transaction?.receiveAtBank === 1) {
        key = `Credit date:`
      }
      return <> {key} {index === 2 ? <br /> : null} {new Date(transaction?.updateDate).toLocaleString('en-US', options) + ` UTC`} </>;
    }
  }

  return (
    <CroboContainer>
      {loading ? (
        <Box py={2}>
          <Loader />
        </Box>
      ) : (
        <Container maxWidth="md">
          {/* Status Card */}
          <Box
            sx={{
              p: { xs: 2, md: 4 },
              my: 2,
              bgcolor: "white",
              borderRadius: 2,
              boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}>
              <AssignmentIcon color={"brand"} />
              <Typography variant="h6" fontWeight={600}>
                Transaction Status
              </Typography>
            </Box>
            <Stepper
              activeStep={transaction?.transactionStep}
              orientation="vertical"
              sx={{
                ".MuiStepConnector-line": {
                  minHeight: 40,
                },
              }}
            >
              {steps.map((label, index) => {
                const labelProps = {};
                if (transactionStatus().status && index === 2) {
                  labelProps.optional = (
                    <Typography variant="caption" color="error">
                      {transaction?.message[`STEP_${index + 1}_ERROR`]}
                    </Typography>
                  );
                  labelProps.error = true;
                }

                return (
                  <Step key={label} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <StepLabel
                      {...labelProps}
                      StepIconComponent={CustomStepIcon}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight:
                            index === transaction?.transactionStep ? 600 : 400,
                        }}
                      >
                        {transaction?.message[`STEP_${index + 1}`]}
                      </Typography>
                      <Typography sx={{ fontSize: 10, fontWeight: 400, color: 'gray' }}>
                        {index === 2 && transaction?.receiveAtBank === 1 ? <>Payout has been processed and the money has been credited into beneficiary's bank account</> : null}
                      </Typography>
                    </StepLabel>
                    <Typography sx={{ fontSize: 10, fontWeight: 400, color: 'gray', minWidth: '30%', marginLeft: 4 }} >
                      {getTransactionTime(index, transaction)}
                    </Typography>
                  </Step>
                );
              })}
            </Stepper>
          </Box>

          {/* Transaction Details Card */}
          <Box
            sx={{
              p: { xs: 2, md: 4 },
              mb: 3,
              bgcolor: "white",
              borderRadius: 2,
              boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}>
              <ReceiptIcon color={"brand"} />
              <Typography variant="h6" fontWeight={600}>
                Transaction Details
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <DetailRow
                label="Transfer Amount"
                value={`$${amountRoundOff(transaction?.base_sub_total)}`}
              />
              <DetailRow
                label="Exchange Rate"
                value={`$1 = ₹${amountRoundOff(
                  transaction?.getbit_exchange_rate
                )}`}
              />
              <DetailRow label="Our fees" value="$0" />
              <DetailRow
                label="Recipient will receive"
                value={`₹${amountRoundOff(transaction?.base_total_amount)}`}
              />
              {transaction?.transactional_time && (
                <DetailRow
                  label="Date & Time"
                  value={getUTCTime(transaction?.transactional_time)}
                />
              )}
              {transaction?.utr_no && (
                <DetailRow
                  label="UTR No"
                  value={transaction?.utr_no}
                  copyable
                />
              )}
              <DetailRow
                label="Transaction Id"
                value={transaction?.trans_id}
                copyable
              />
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}>
              <PersonIcon color={"brand"} />
              <Typography variant="h6" fontWeight={600}>
                Recipient Details
              </Typography>
            </Box>

            <Box>
              <DetailRow label="Account Holder" value={transaction?.bankName} />
              <DetailRow
                label="Account Number"
                value={transaction?.bankAccountNumber}
                copyable
              />
              <DetailRow
                label="IFSC code"
                value={transaction?.bankIFSC}
                copyable
              />
            </Box>
          </Box>

          {/* Support Button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              py: 3,
              mb: { xs: 6, md: 10 },
            }}
          >
            <SupportButton />
          </Box>
        </Container>
      )}
    </CroboContainer>
  );
};

export default TransactionDetails;
